<template>
  <div class="public-scroll-transparent public-page-style">
    <el-form :inline="true" :model="searchForm" class="iot-warning-form">
      <el-form-item :label="alarmConfig.title3" prop="startDate">
        <el-date-picker
          v-model="searchForm.startDate"
          type="date"
          placeholder="选择开始日期"
          size="small"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="alarmConfig.title4" prop="endDate">
        <el-date-picker
          v-model="searchForm.endDate"
          type="date"
          placeholder="选择结束日期"
          value-format="yyyy-MM-dd"
          size="small"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="alarmConfig.title2" prop="projectGroupId">
        <el-select
          v-model="searchForm.projectGroupId"
          placeholder="请选择项目组"
          clearable
          filterable
          size="small"
        >
          <el-option
            v-for="item in projectGroupList"
            :key="item.projectGroupId"
            :label="item.projectGroupName"
            :value="item.projectGroupId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="alarmConfig.title6" prop="seriousAlarm">
        <el-select
          v-model="searchForm.seriousAlarm"
          placeholder="请选择告警类型"
          clearable
          size="small"
        >
          <el-option
            v-for="item in constant.seriousAlarm"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            () => {
              $set(searchForm, 'current', 1);
              getList();
            }
          "
          size="small"
          plain
          >查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-download"
          size="small"
          v-show="$store.state.permissionArr.indexOf('alarmInfo:download') > -1"
          @click="downloadData"
          >下载数据</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      size="medium"
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column
        prop="projectName"
        width="200"
        :label="alarmConfig.title1"
      ></el-table-column>
      <el-table-column
        width="200"
        prop="projectGroupName"
        :label="alarmConfig.title2"
      ></el-table-column>
      <el-table-column
        prop="startTime"
        :label="alarmConfig.title3"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="endDate"
        :label="alarmConfig.title4"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="alarmDuration"
        :label="alarmConfig.title5"
      ></el-table-column>
      <el-table-column prop="alarmState" :label="alarmConfig.title6">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.alarmType == 2" type="danger">{{
            scope.row.alarmState
          }}</el-tag>
          <el-tag v-else type="info">{{ scope.row.alarmState }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="alarmInfo"
        :label="alarmConfig.title7"
        width="350"
      ></el-table-column>
      <el-table-column prop="createTime" :label="alarmConfig.title8">
        <template slot-scope="scope">
          <el-button
            @click="camera(scope.row.alarmInfoId)"
            type="warning"
            icon="el-icon-camera-solid"
            circle
          >
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" :label="alarmConfig.title9">
        <template slot-scope="scope">
          <el-popover
            placement="left"
            width="550"
            trigger="click"
          >
            <el-table :data="infoData" style="width: 100%">
              <el-table-column prop="initiator" label="操作人" width="100">
              </el-table-column>
              <el-table-column prop="operaContext" label="备注" width="320">
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button
                    @click="getInfo(scope.row.alarmInfoId)"
                    type="primary"
                    plain
                    size="mini"
                    >修改</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-button
              slot="reference"
              @click="editInfo(scope.row.alarmInfoId)"
              type="primary"
              plain
              size="mini"
              >查看</el-button
            >
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" :label="alarmConfig.title10">
        <template slot-scope="scope">
          <el-button
            v-if="$store.state.permissionArr.indexOf('alarmInfo:delete') > -1"
            @click="delAlarm(scope.row.alarmInfoId)"
            type="danger"
            plain
            size="mini"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin: 10px"
      @current-change="handleCurrentChange"
      :current-page="searchForm.current"
      :page-size="searchForm.size"
      layout="total, prev, pager, next, jumper"
      :total="total"
      :prev-click="handleCurrentChange"
      :next-click="handleCurrentChange"
    >
    </el-pagination>
    <el-drawer title="告警图片" :visible.sync="drawer" size="100%">
      <el-carousel
        indicator-position="outside"
        height="900px"
        :autoplay="false"
      >
        <el-carousel-item v-for="item in ysImg" :key="item.id">
          <img :src="imgs + item.ysImge" style="width: 100%" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-drawer>
    <el-dialog title="巡检记录" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form ref="form" :model="infoForm" label-width="80px">
          <el-form-item label="巡检人">
            <el-input v-model="infoForm.initiator"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input
              type="textarea"
              v-model="infoForm.operaContext"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitInfo">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
let that;
import { TABLE_HEADER_COLOR } from "../util/theme";
import { seriousAlarm } from "../util/constant";
import { imgs } from "../util/global";
export default {
  name: "Warning",
  props: {
    msg: String,
  },
  data() {
    return {
      edit: false,
      infoData: [],
      infoForm: {
        initiator: "",
        operaContext: "",
      },
      dialogVisible: false,
      alarmConfig: {},
      ysImg: [],
      drawer: false,
      imgs: imgs,
      constant: { seriousAlarm },
      theme: { TABLE_HEADER_COLOR },
      searchForm: {
        startDate: "",
        endDate: "",
        projectGroupId: "",
        seriousAlarm: "",
        current: 1,
        size: 10,
      },
      tableData: [],
      total: 0,
      projectGroupList: [],
      projectList: [],
      seriousAlarmList: [],
      levelList: [],
    };
  },
  methods: {
    downloadData() {
      const { startDate, endDate, projectGroupId, seriousAlarm } = this.searchForm
      let from = {
        startDate, endDate, projectGroupId, seriousAlarm
      }
      this.$api.warning.downLoadData(from).then((res) => {
        if (res.code == 200) {
          window.open(res.data)
          this.$message.success(res.msg)
        }
      })
    },
    getInfo(alarmInfoId) {
      that.$api.warning.getAlarmInfoLog(alarmInfoId).then((res) => {
        if (res.code == 200) {
          that.infoForm = res.data[0];
          that.dialogVisible = true;
        }
      });
    },
    editInfo(alarmInfoId) {
      that.$api.warning.getAlarmInfoLog(alarmInfoId).then((res) => {
        if (res.code == 200) {
          if (res.data.length < 1) {
            that.infoData=[];
            that.edit = false;
            that.infoForm = {
              initiator: "",
              operaContext: "",
              alarmInfoId: alarmInfoId,
            };
            that.dialogVisible = true;
          } else {
             that.infoData = res.data;
            that.edit = true;
           
          }
        }
      });
    },
    delInfo(alarmInfoId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          type: "warning",
          cancelButtonClass: "cancelBtn",
        })
        .then(() => {
          that.$api.warning.delAlarmInfoLog(alarmInfoId).then((response) => {
            if (response.code == 200) {
              that.edit = false;
              that.$message({
                type: "success",
                message: "删除成功",
              });
            }
          });
        })
        .catch(() => {});
    },
    submitInfo() {
      let api = !that.edit
        ? that.$api.warning.postAlarmInfoLog(that.infoForm)
        : that.$api.warning.putAlarmInfoLog(that.infoForm);
      api.then((res) => {
        if (res.code == 200) {
          that.dialogVisible = false;
        }
      });
    },
    handleCurrentChange: function (current) {
      that.$set(that.searchForm, "current", current);
      that.getList();
    },
    delAlarm: function (id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          type: "warning",
          cancelButtonClass: "cancelBtn",
        })
        .then(() => {
          that.$api.warning.delAlarm(id).then((response) => {
            if (response.code == 200) {
              that.tableData = response.data.records;
              that.total = response.data.total;
              that.getList();
            }
          });
        })
        .catch(() => {});
    },
    //获取告警配置
    getAlarmConfig() {
      this.$api.SetTitleConfig.getAlarmConfig().then((res) => {
        this.alarmConfig = res.data;
      });
    },
    //报警列表
    getList: function () {
      let _d = that.searchForm;

      if (!_d.startDate) {
        _d.startDate = "";
        _d.endDate = "";
      }

      if (!_d.endDate) {
        _d.endDate = "";
      }
      if (_d.startDate && !_d.endDate) {
        let date = new Date();
        let month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        let dates = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        _d.endDate = date.getFullYear() + "-" + month + "-" + dates;
      }

      that.$api.warning.getWarningPage(_d).then((response) => {
        if (response.code == 200) {
          that.tableData = response.data.records;
          that.total = response.data.total;
        }
      });
    },
    //项目组列表
    getGroup: function () {
      that.$api.warning.getProjectGroup().then((response) => {
        if (response.code == 200) {
          that.projectGroupList = response.data;
        }
      });
    },
    camera: function (id) {
      that.$api.warning.getYsImage(id).then((res) => {
        if (res.code == 200) {
          that.drawer = true;
          that.ysImg = res.data;
        }
      });
    },
  },
  beforeMount: function () {
    that = this;
  },
  mounted: function () {
    let date = new Date();
    let full = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let endDate = full + "-" + month + "-" + Day;
    // if (Day > 6) {
    //   Day -= 6;
    //   Day = Day < 10 ? "0" + Day : Day;
    // } else {
    //   if (month - 1 < 1) {
    //     full -= 1;
    //     month = 12;
    //   } else {
    //     month -= 1;
    //     if (month<10) {
    //       month='0'+month;
    //     }
    //   }
    //   Day = new Date(full, month, 0).getDate()*1 + Day*1 - 6;
    // }

    // let startDate = full + "-" + month + "-" + Day;
    that.searchForm.startDate=endDate
    that.searchForm.endDate=endDate
    that.getAlarmConfig();
    that.getGroup();
    that.getList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to that component only -->
<style scoped>
.iot-warning-form >>> .el-date-editor.el-input,
iot-warning-form >>> .el-date-editor.el-input__inner {
  width: 140px;
}
.iot-warning-form >>> .el-input {
  width: 140px;
}
</style>
